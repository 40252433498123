import React from 'react';

const AccountTitle = ({ userName }) => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-center w-100">
      <div className="rounded-circle bg-white text-dark p-4" />

      <div className="d-flex flex-column text-start ms-3 flex-grow-1">
        <span
          className="fw-bold text-truncate pe-4"
          style={{ fontSize: '1rem', width: '160px' }}
          title={userName}
        >
          {userName}
        </span>
      </div>
    </div>
  );
};

export default AccountTitle;
