import React from 'react';

export const Row = ({ row }) => {
  return (
    <div className="row border-bottom">
      <div className="col d-flex justify-content-center p-3 fw-bold">
        {row.reportName}
      </div>
      {row?.items.map((col, colIndex) => (
        <div
          key={`${col}-${colIndex}`}
          className="col d-flex justify-content-center p-3"
        >
          {col?.submitted}/{col?.total}
        </div>
      ))}
    </div>
  );
};
