import { toast } from 'react-toastify';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'src/utils/api';
import { IResource } from 'src/models/Resource';
import { IProjectDetails } from 'src/models/Project';
import { formatDate } from 'src/utils/helpers';

export const fetchAllProjects: any = createAsyncThunk(
  'projects/fetchAllProjects',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await client.get(`Project`);

      return response.data;
    } catch (err) {
      toast.error('Error fetching timesheet.');

      return rejectWithValue(err);
    }
  },
);

export const searchProjects: any = createAsyncThunk(
  'projects/searchProjects',
  async ({ query }: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`Project/search/${query}`);

      return response.data;
    } catch (err) {
      toast.error('Error fetching timesheet.');

      return rejectWithValue(err);
    }
  },
);

export const fetchAssignedProjectsByResource: any = createAsyncThunk(
  'projects/fetchAssignedProjectsByResource',
  async ({ resource }: any, { rejectWithValue }) => {
    try {
      console.log(' resource ', resource);
      const response = await client.get(
        `Project/assigned?resource=${resource}`,
        {
          // body: { resource },
        },
      );

      return response.data as IProjectDetails[];
    } catch (err) {
      toast.error(`Error fetching assigned projects for ${resource}.`);

      return rejectWithValue(err);
    }
  },
);

export const fetchProjectsById: any = createAsyncThunk(
  'projects/fetchProjectsById',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`Project/${id}`, {
        // body: { resource },
      });

      const formattedData: IProjectDetails = { ...response.data };

      const resources: IResource[] = response?.data?.resources.map(
        (item: IResource, index) => {
          const resource = {
            ...item,
            id: index,
            isNewResource: false,
            startDate: formatDate(item.startDate),
            endDate: formatDate(item.endDate),
          };

          return resource;
        },
      );

      formattedData.resources = [...resources];

      return formattedData;
    } catch (err) {
      toast.error('Error fetching timesheet.');

      return rejectWithValue(err);
    }
  },
);

export const assignProject: any = createAsyncThunk(
  'projects/assignProject',
  async ({ id, payload }: any, { rejectWithValue }) => {
    try {
      const reqObj = payload.map((item: IResource) => {
        return {
          email: item.resource,
          startDate: item.startDate,
          endDate: item.endDate,
          status: item.status,
          isActive: item.isActive,
        };
      });
      const response = await client.post(`Project/${id}/assign`, reqObj);

      return response.data;
    } catch (err) {
      toast.error('Error adding resource.');

      return rejectWithValue(err);
    }
  },
);

enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    data: [],
    searchResults: [],
    project: {
      status: Status.idle,
      loading: false,
      data: {},
      error: '',
    },
    error: '',
    status: Status.idle,
    loading: false,
  },
  reducers: {
    resetProjects: (state) => {
      state.data = [];
      state.project = {
        status: Status.idle,
        loading: false,
        data: {},
        error: '',
      };
      state.error = '';
      state.status = Status.idle;
      state.loading = false;
    },
  },
  extraReducers: {
    [assignProject.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [assignProject.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
      state.status = Status.succeeded;
    },
    [assignProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
    [searchProjects.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [searchProjects.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchResults = action.payload;
      state.status = Status.succeeded;
    },
    [searchProjects.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
    [fetchAllProjects.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [fetchAllProjects.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.status = Status.succeeded;
    },
    [fetchAllProjects.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
    [fetchAssignedProjectsByResource.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [fetchAssignedProjectsByResource.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.status = Status.succeeded;
    },
    [fetchAssignedProjectsByResource.rejected]: (state, action) => {
      state.loading = false;
      state.status = Status.failed;
      state.data = [];
      state.error = action.error.message;
    },
    [fetchProjectsById.pending]: (state) => {
      state.project.loading = true;
      state.project.status = Status.loading;
    },
    [fetchProjectsById.fulfilled]: (state, action) => {
      state.project.loading = false;
      state.project.data = { ...action.payload };
      state.project.status = Status.succeeded;
    },
    [fetchProjectsById.rejected]: (state, action) => {
      state.project.loading = false;
      state.project.status = Status.failed;
      state.project.error = action.error.message;
    },
  },
});

export const getProjects = (state) => {
  return {
    data: state.projects.data,
    status: state.projects.status,
    loading: state.projects.loading,
  };
};

export const getProjectById = (state) => {
  return {
    loading: state?.projects?.project?.loading,
    data: {
      ...state?.projects?.project?.data,
      resources: [
        ...(state?.projects?.project?.data?.resources || []).map((item) => ({
          ...item,
        })),
      ],
    },
  };
};

const actions = projectsSlice.actions;

export { actions };
export default projectsSlice.reducer;
