// React imports
import React, { useEffect, useState } from 'react';

// App imports
import { isAuthorised, parseRoles } from 'src/utils/helpers';

const CanView = ({ userRole, allowedRoles, children }) => {
  const [roles] = useState<string[]>(parseRoles(userRole));
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const authorised = isAuthorised(roles, allowedRoles);

    setHasAccess(authorised);
  }, [roles, allowedRoles]);

  if (hasAccess) {
    return <>{children}</>;
  }

  return null;
};

export { CanView };
