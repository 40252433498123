import React, { useEffect, useRef, useState } from 'react';

const EditInput = ({
  rowIndex,
  colIndex,
  update,
  value,
  step = 0.5,
  disabled = false,
  className = '',
  isTotalInput = false,
  style = {},
  max = 24,
}) => {
  const inputEl = useRef(null);
  const [isOverTime, setIsOverTime] = useState(value > 8);
  const [displayValue, setDisplayValue] = useState(value);

  const handleFocus = () => {
    inputEl.current.select();
  };

  const onChange = (e) => {
    let inputValue = e.target.value;

    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(inputValue)) {
      if (inputValue === '.') {
        inputValue = '.';
      } else if (inputValue && !isNaN(Number(inputValue))) {
        const parsedValue = parseFloat(inputValue);

        if (parsedValue > max) {
          return;
        }
        const roundedValue = Math.round(parsedValue * 2) / 2;

        if (roundedValue % 1 === 0) {
          inputValue = roundedValue.toString().replace('.0', '');
        } else {
          inputValue = roundedValue;
        }

        if (roundedValue < 1) {
          inputValue = `${inputValue}`;
        }
      }

      setDisplayValue(inputValue);
      update({ target: { value: inputValue } }, rowIndex, colIndex);

      if (parseFloat(inputValue) > 8) {
        setIsOverTime(true);
      } else {
        setIsOverTime(false);
      }
    } else {
      setDisplayValue(value);
    }
  };

  const handleKeyDown = (e) => {
    const { key } = e;

    if (key === 'ArrowDown' || key === 'ArrowUp') {
      e.preventDefault();
      const currentElement = e.target;
      const rowElement = currentElement.closest('.row');
      const siblingRow =
        key === 'ArrowDown'
          ? rowElement.nextSibling
          : rowElement.previousSibling;

      if (siblingRow) {
        const inputElements = siblingRow.querySelectorAll('input');
        const targetInput = inputElements[colIndex + 1] || inputElements[0];

        if (targetInput) {
          targetInput.focus();
        }
      }
    }
  };

  useEffect(() => {
    setDisplayValue(value);
    setIsOverTime(value > 8);
  }, [value]);

  useEffect(() => {
    if (value > 8) {
      setIsOverTime(true);
    } else {
      setIsOverTime(false);
    }
  }, [value]);

  return (
    <input
      ref={inputEl}
      type="number"
      className={`form-control ${className} ${!isTotalInput && isOverTime ? 'border-warning border-2' : ''}`}
      placeholder="0.0"
      value={displayValue}
      onChange={onChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      step={step}
      style={style}
      min={0}
      max={max}
    />
  );
};

export default EditInput;
