import React from 'react';
import { Card, Stack } from 'react-bootstrap';

export const ReportCards = () => {
  return (
    <Stack direction="horizontal" gap={5} className="">
      <Card
        bg="light"
        text="dark"
        className="mb-4 bg-white shadow border-0 w-25"
      >
        <Card.Body className="p-4 d-flex flex-column align-items-start">
          <span className="fw-bold">Total Reports</span>
          <span className="fw-bolder fs-3 pt-2">10 Reports</span>
        </Card.Body>
      </Card>

      <Card
        bg="light"
        text="dark"
        className="mb-4 bg-white shadow border-0 w-25"
      >
        <Card.Body className="p-4 d-flex flex-column align-items-start">
          <span className="fw-bold">Total Hours Billable</span>
          <span className="fw-bolder fs-3 pt-2">1600 Hours</span>
        </Card.Body>
      </Card>

      <Card
        bg="light"
        text="dark"
        className="mb-4 bg-white shadow border-0 w-25"
      >
        <Card.Body className="p-4 d-flex flex-column align-items-start">
          <span className="fw-bold">Total Non Submitted</span>
          <span className="fw-bolder fs-3 pt-2">400 Hours</span>
        </Card.Body>
      </Card>

      <Card
        bg="light"
        text="dark"
        className="mb-4 bg-white shadow border-0 w-25"
      >
        <Card.Body className="p-4 d-flex flex-column align-items-start">
          <span className="fw-bold">Total Submitted</span>
          <span className="fw-bolder fs-3 pt-2">1200 Hours</span>
        </Card.Body>
      </Card>
    </Stack>
  );
};
