export const DashboardTableHeader = ({ tableColumns }) => {
  return (
    <div className="row border-bottom border-dark">
      {tableColumns.map((column, columnIndex) => (
        <div className={`p-3 fw-bold col`} key={`${column}-${columnIndex}`}>
          {column}
        </div>
      ))}
    </div>
  );
};
