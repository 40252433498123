import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { DashboardTableHeader } from './Header';
import { Row } from './Row';
import { ScrollableContent } from 'src/components/ScrollableContent';

interface GridTableProps {
  data: any;
  columns: any;
}
const DashboardTable = ({ data, columns }: GridTableProps) => {
  const [tableData] = useState(data.items);
  const [tableColumns] = useState(columns);

  return (
    <div>
      <DashboardTableHeader tableColumns={tableColumns} />
      <ScrollableContent minHeight="500px" maxHeight="500px">
        {tableData?.map((row, index) => <Row row={row} key={index} />)}
      </ScrollableContent>
      <div className="d-flex align-items-center mt-3 justify-content-between">
        <nav aria-label="Page navigation example" className="ms-2">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="/dashboard" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/dashboard">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/dashboard">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/dashboard">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/dashboard" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
        <Stack
          direction="horizontal"
          gap={2}
          className="align-items-center justify-content-center"
        >
          <i className="bi bi-info-circle-fill text-primary fs-5"></i>
          <span>Eg: 21 of 31 Submitted</span>
        </Stack>
      </div>
    </div>
  );
};

export default DashboardTable;
