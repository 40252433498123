// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
import { User } from 'oidc-client-ts';

const apiURL = process.env.REACT_APP_API_ENDPOINT;
const userApiURL = process.env.REACT_APP_USER_API_ENDPOINT;
const ssoAuthority = process.env.REACT_APP_SSO_AUTHORITY;
const ssoClientId = process.env.REACT_APP_SSO_CLIENT_ID;

function getUser() {
  // TODO: get it form env
  const oidcStorage = localStorage.getItem(
    `oidc.user:${ssoAuthority}:${ssoClientId}`,
  );

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export async function client(endpoint, { body, ...customConfig }: any = {}) {
  const headers = { 'Content-Type': 'application/json' };
  const user = getUser();
  const token = user?.access_token;

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;

  try {
    const response = await window.fetch(endpoint, config);

    data = await response.json();

    if (response.ok) {
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    }

    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
}

client.get = function (endpoint, customConfig = {}, userApi = false) {
  let apiEndpoint = `${apiURL}/${endpoint}`;

  if (userApi) {
    apiEndpoint = `${userApiURL}/${endpoint}`;
  }

  return client(apiEndpoint, { ...customConfig, method: 'GET' });
};

client.post = function (endpoint, body = {}, customConfig = {}) {
  let apiEndpoint = `${apiURL}/${endpoint}`;

  if (endpoint.includes('api/user/filter')) {
    apiEndpoint = `${userApiURL}/${endpoint}`;
  }

  return client(apiEndpoint, { ...customConfig, body });
};

client.put = function (endpoint, body = {}, customConfig = {}) {
  return client(`${apiURL}/${endpoint}`, {
    ...customConfig,
    body,
    method: 'PUT',
  });
};
