import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'src/utils/api';

export const fetchActivities: any = createAsyncThunk(
  'session/fethcActivities',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await client.get('Activity');

      return response.data;
    } catch (err) {
      console.log(err);

      return rejectWithValue(err);
    }
  },
);

export const fetchAssignedProjects: any = createAsyncThunk(
  'session/fetchAssignedProjects',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`Project/assigned`, {
        // body: { resource },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

const defaultState = {
  preferences: {
    teamTime: false,
    showMenu: false,
  },
  activities: {
    data: [],
    error: '',
    status: Status.idle,
    loading: false,
  },
  assignedProjects: {
    data: [],
    error: '',
    status: Status.idle,
    loading: false,
  },
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: { ...defaultState },
  reducers: {
    resetSession: (state) => {
      state.activities = { ...defaultState.activities };
    },
    setTeamTime: (state, action) => {
      state.preferences = { ...state.preferences, teamTime: action.payload };
    },
    setOffCanvasMenu: (state) => {
      state.preferences = {
        ...state.preferences,
        showMenu: !state.preferences.showMenu,
      };
    },
  },
  extraReducers: {
    [fetchActivities.pending]: (state) => {
      state.activities.loading = true;
      state.activities.status = Status.loading;
    },
    [fetchActivities.fulfilled]: (state, action) => {
      state.activities.loading = false;
      state.activities.data = action.payload;
      state.activities.status = Status.succeeded;
    },
    [fetchActivities.rejected]: (state, action) => {
      state.activities.loading = false;
      state.activities.status = Status.failed;
      state.activities.error = action.error.message;
    },
    [fetchAssignedProjects.pending]: (state) => {
      state.assignedProjects.loading = true;
      state.assignedProjects.status = Status.loading;
    },
    [fetchAssignedProjects.fulfilled]: (state, action) => {
      state.assignedProjects.loading = false;
      state.assignedProjects.data = action.payload;
      state.assignedProjects.status = Status.succeeded;
    },
    [fetchAssignedProjects.rejected]: (state, action) => {
      state.assignedProjects.loading = false;
      state.assignedProjects.status = Status.failed;
      state.assignedProjects.data = [];
      state.assignedProjects.error = action.error.message;
    },
  },
});

export const getPreferences = (state) => {
  return { ...state.session.preferences };
};

export const getActivities = (state) => {
  return {
    data: state.session.activities.data,
    status: state.session.activities.status,
    loading: state.session.activities.loading,
  };
};

export const getAssignedProjects = (state) => {
  return {
    data: state.session.assignedProjects.data,
    status: state.session.assignedProjects.status,
    loading: state.session.assignedProjects.loading,
  };
};

const actions = sessionSlice.actions;

export { actions };
export default sessionSlice.reducer;
