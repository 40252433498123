// React imports
import React, { useState } from 'react';

// Third-party imports
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from 'react-redux';

// App imports
import { IProjectResponse } from 'src/models/Project';
import { fetchAllUsers } from 'src/redux/features/users/usersSlice';

export const ResourceAutoComplete = ({
  selectedItems,
  onSelectedResourceChange,
  disabled = false,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<IProjectResponse[]>([]);

  const handleSearch = async (query: string) => {
    setIsLoading(true);

    const response = await dispatch(fetchAllUsers({ query }));

    if (response?.payload && Array.isArray(response?.payload)) {
      setOptions(response?.payload.filter((x) => x.name));
    } else {
      setOptions([]);
    }
    setIsLoading(false);
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id="async-example"
      isLoading={isLoading}
      labelKey="name"
      minLength={3}
      onSearch={handleSearch}
      selected={selectedItems}
      options={options}
      onChange={onSelectedResourceChange}
      placeholder="Select Resource"
      disabled={disabled}
      allowNew={false}
      style={{ minWidth: '300px' }}
      renderMenuItemChildren={(option: any) => (
        <>
          <span>{option.name}</span>
        </>
      )}
    />
  );
};
