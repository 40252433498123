// React imports
import React, { Fragment, useEffect, useState } from 'react';

// Third party imports
import { Form } from 'react-bootstrap';

// App imports
import { GridTableHeader } from './Header';
import { GridTableTotalHeader } from './TotalHeader';
import { ScrollableContent } from '../../../../components/ScrollableContent';
import { GridTableBodyActivityRow } from './ActivityRow';
import { GridTableBodyRow } from './TableBodyRow';
import { ITimesheet } from 'src/models/Timesheet';
import { splitAndDistributeHours } from 'src/utils/generateTimesheet';
import { generateDates } from 'src/utils/helpers';
import { useSelector } from 'react-redux';
import { getActivities } from 'src/redux/features/session/slice';

interface GridTableProps {
  data: ITimesheet;
  columns: any;
  startDate: string;
  endDate: string;
  disabled?: boolean;
  isSubmitted?: boolean;
  weekType?: 'week' | 'work_week';
}

export const GridTable = ({
  data,
  columns,
  startDate,
  endDate,
  disabled,
  weekType,
  isSubmitted,
}: GridTableProps) => {
  const [validated, setValidated] = useState(false);

  const [projects, setProjects] = useState(data.projects);
  const [tableColumns, setTableColumns] = useState(columns);
  const { data: activities } = useSelector(getActivities);

  useEffect(() => {
    data.projects.forEach((val) => {
      val.isExpanded = true;
    }); // Expand all activities by default.
    setProjects(data.projects);
  }, [data, data.projects]);

  useEffect(() => {
    setTableColumns(columns);
  }, [columns]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  /**
   * The function `onActivityRowDurationChange` updates the duration value in a table data structure and
   * calculates the total duration for a specific column.
   * @param event - The `event` represents the
   * event that triggered the change, such as a user input
   * @param rowIndex - The `rowIndex` parameter refers to the index of the row within
   * the `activityRows` array that is being modified when the duration of an activity row is changed.
   * @param colIndex - The `colIndex` parameter represents the index of the column in the table
   * where the duration value is being changed.
   * @param index - The `index` represents the index of the table data that is being modified or updated.
   * It is used to identify which specific table data object needs to be updated when changing the duration of an activity row.
   */
  const onActivityRowDurationChange = (
    parseValue,
    rowIndex,
    colIndex,
    index,
  ) => {
    projects[index]['activityRows'][rowIndex]['items'][colIndex].duration =
      parseValue;
    let totalDuration = 0;

    projects[index]?.activityRows.forEach((activity) => {
      totalDuration += activity?.items[colIndex].duration;
    });

    projects[index]['totalRowHeaders'][colIndex] = {
      ...projects[index]['totalRowHeaders'][colIndex],
      duration: totalDuration,
    };

    setProjects([...projects]);
  };

  /**
   * The function `onBodyRowDurationChange` updates durations for activities in a table based on user
   * input. The user entered value would be a split among the activity rows.
   * @param value - The `value` parameter object that represents updated value.
   * @param rowIndex - The `rowIndex` parameter refers to the index of the row in the
   * projects array that is being modified or updated based on the user input.
   * @param colIndex - The `colIndex` parameter represents the
   * index of the column in the table where the duration value is being changed.
   * @param index - The `index` parameter in the `onBodyRowDurationChange` function represents the index
   * of the current row being modified in the `projects` array. It is used to access and update the
   * specific row and its associated activity rows and durations based on the user input.
   */
  const onBodyRowDurationChange = (value, rowIndex, colIndex) => {
    const parseValue = Number(value);
    const currentRow = projects[rowIndex];
    const hasActivities = currentRow?.activityRows?.length;

    if (hasActivities) {
      const activityRowCount = currentRow.activityRows.length;
      const durationPerActivity = splitAndDistributeHours(
        parseValue,
        activityRowCount,
      );

      currentRow.activityRows.forEach((activity, index) => {
        activity['items'][colIndex].duration = durationPerActivity[index];
      });
      projects[rowIndex]['totalRowHeaders'][colIndex].duration = parseValue;
      projects[rowIndex] = { ...currentRow };
    } else {
      projects[rowIndex]['totalRowHeaders'][colIndex].duration = parseValue;
    }
    setProjects([...projects]);
  };

  const onBodyRowTotalHeaderDurationChange = (parseValue, index) => {
    const updatedProjects = [...projects];

    const projectRowCount = updatedProjects[index].totalRowHeaders.length;
    const durationPerProject = splitAndDistributeHours(
      parseValue,
      projectRowCount,
    );

    const updatedTotalRowHeaders = [...updatedProjects[index].totalRowHeaders];

    updatedTotalRowHeaders.forEach((item, itemIndex) => {
      item.duration = durationPerProject[itemIndex];
      updateActivityRowDuration(
        durationPerProject[itemIndex],
        index,
        itemIndex,
      );
    });

    updatedProjects[index].totalRowHeaders = [...updatedTotalRowHeaders];

    setProjects([...updatedProjects]);
  };

  const updateActivityRowDuration = (value, index, colIndex) => {
    const updatedProjects = [...projects];

    const activityRowCount = updatedProjects[index].activityRows.length;
    const durationPerActivity = splitAndDistributeHours(
      value,
      activityRowCount,
    );

    const updatedActivityRows = [...updatedProjects[index].activityRows];

    updatedActivityRows.forEach((activity, activityIndex) => {
      activity.items[colIndex].duration = durationPerActivity[activityIndex];
    });

    updatedProjects[index].activityRows = [...updatedActivityRows];
    setProjects([...updatedProjects]);
  };

  const updateRowTotalHeaderDuration = (index, colIndex) => {
    const updatedProjects = [...projects];

    let totalDuration = 0;

    updatedProjects[index].activityRows.forEach((activity) => {
      totalDuration += activity.items[colIndex].duration;
    });

    const updatedTotalRowHeaders = [...updatedProjects[index].totalRowHeaders];

    updatedTotalRowHeaders[colIndex].duration = totalDuration;

    updatedProjects[index].totalRowHeaders = [...updatedTotalRowHeaders];
    setProjects([...updatedProjects]);
  };

  const onActivityRowTotalHeaderDurationChange = (
    parseValue,
    index,
    activityIndex,
  ) => {
    const updatedProjects = [...projects];

    const projectRowCount = updatedProjects[index]?.totalRowHeaders?.length;
    const durationPerProject = splitAndDistributeHours(
      parseValue,
      projectRowCount,
    );

    updatedProjects[index].activityRows[activityIndex].items.forEach(
      (item, itemIndex) => {
        item.duration = durationPerProject[itemIndex];
        updateRowTotalHeaderDuration(index, itemIndex);
      },
    );

    setProjects([...updatedProjects]);
  };

  /**
   * The `headerRowSum` function calculates the total duration of all rows in the `tableColumns` array
   * and logs the result to the console.
   * @returns The function `headerRowSum` is returning the total sum of the `total` property from each
   * object in the `tableColumns` array.
   */
  const headerRowSum = () => {
    const totalDuration = tableColumns.reduce((accumulator, row) => {
      return accumulator + row.total;
    }, 0);

    return totalDuration;
  };

  /**
   * The function `onTotalHeaderDurationChange` updates the total duration value in a table column based
   * on user input.
   * @param event - The `event` parameter is an object that represents the change event on an input field.
   * @param colIndex - The `colIndex` parameter in the `onTotalHeaderDurationChange` function represents
   * the index of the column in the table where the total duration is being changed.
   */
  const onTotalHeaderDurationChange = (event, rowIndex, colIndex) => {
    const parseValue = Number(event.target.value);

    const updatedColumns = [...tableColumns];

    updatedColumns[colIndex].total = parseValue;

    const projectRowCount = projects.length;
    // const durationPerProject = parseValue / projectRowCount;

    const durationDistributionPerProject = splitAndDistributeHours(
      parseValue,
      projectRowCount,
    );

    projects.forEach((_project, index) => {
      onBodyRowDurationChange(
        durationDistributionPerProject[index],
        index,
        colIndex,
      );
    });

    setTableColumns([...updatedColumns]);
  };

  /**
   * The function `addActivityRow` adds a new activity row with multiple items to a specific project in a
   * list of projects.
   * @param index - The `index` parameter represents the index of the project.
   * @param projectId - projectId is the unique identifier of the project to which the new activity row will be added.
   */
  const addActivityRow = (index, projectId) => {
    const updatedProjects = [...projects];

    const existingActivities = [];

    updatedProjects[index].activityRows.forEach((activityRow) => {
      existingActivities.push(activityRow.activityName);
    });

    const availableActivities = activities.filter(
      (activity) => !existingActivities.includes(activity.name),
    );

    console.log('availableActivities ', availableActivities);

    if (!availableActivities.length) {
      return;
    }

    const dates = generateDates(startDate, endDate, weekType === 'work_week');

    // const newActivityRow = {
    //   activityName: availableActivities[0].name,
    //   items: [
    //     {
    //       duration: 0,
    //       activityName: availableActivities[0].name,
    //       activityTypeId: availableActivities[0].id,
    //       projectId: projectId,
    //       date: dates[0],
    //     },
    //     {
    //       duration: 0,
    //       activityName: availableActivities[0].name,
    //       activityTypeId: availableActivities[0].id,
    //       projectId: projectId,
    //       date: dates[1],
    //     },
    //     {
    //       duration: 0,
    //       activityName: availableActivities[0].name,
    //       activityTypeId: availableActivities[0].id,
    //       projectId: projectId,
    //       date: dates[2],
    //     },
    //     {
    //       duration: 0,
    //       activityName: availableActivities[0].name,
    //       activityTypeId: availableActivities[0].id,
    //       projectId: projectId,
    //       date: dates[3],
    //     },
    //     {
    //       duration: 0,
    //       activityName: availableActivities[0].name,
    //       activityTypeId: availableActivities[0].id,
    //       projectId: projectId,
    //       date: dates[4],
    //     },
    //   ],
    //   isNew: true,
    // };

    const newActivityRow = {
      activityName: availableActivities[0].name,
      items: [],
      isNew: true,
    };

    for (const date of dates) {
      newActivityRow.items.push({
        duration: 0,
        activityName: availableActivities[0].name,
        activityTypeId: availableActivities[0].id,
        projectId: projectId,
        date: date,
      });
    }

    updatedProjects[index].activityRows.push(newActivityRow);
    setProjects([...updatedProjects]);
  };

  /**
   * The `removeActivityRow` function removes a specific activity row from a project and recalculates the
   * total duration for each column in the remaining activity rows.
   * @param index - The `index` parameter represents the index of the project to remove an activity row.
   * @param activityIndex - The `activityIndex` represents the index of the activity
   * row that you want to remove from a specific project.
   */
  const removeActivityRow = (index, activityIndex) => {
    const updatedProjects = [...projects];

    updatedProjects[index].activityRows.splice(activityIndex, 1);

    /* Calculate the total duration for each column in the `activityRows` post deleting the row for a specific project. */
    for (let i = 0; i < columns.length; i++) {
      let totalDuration = 0;

      updatedProjects[index]?.activityRows.forEach((activity) => {
        totalDuration += activity?.items[i]?.duration;
      });

      projects[index]['totalRowHeaders'][i] = {
        ...projects[index]['totalRowHeaders'][i],
        duration: totalDuration,
      };
    }

    setProjects(updatedProjects);
  };

  const onActivityValueChange = (value, index, activityIndex) => {
    const updatedProjects = [...projects];

    const [val, id] = value.split('-');

    updatedProjects[index].activityRows[activityIndex].activityName = val;
    updatedProjects[index].isExpanded = true;

    let previousItems = [];

    const previousWeekActivity = updatedProjects[
      index
    ].previousWeekActivityRows.filter((item) => item.activityName === val)[0];

    if (previousWeekActivity) {
      previousItems = previousWeekActivity.items;
    }

    updatedProjects[index].activityRows[activityIndex].items.forEach(
      (item, itemIndex) => {
        item.activityName = val;
        item.activityTypeId = Number(id);

        item.duration = previousItems[itemIndex]?.duration || 0;

        // onActivityRowDurationChange(
        //   previousItems[itemIndex]?.duration || 0,
        //   activityIndex,
        //   itemIndex,
        //   index,
        // );
      },
    );

    setProjects([...updatedProjects]);

    updatedProjects[index].activityRows[activityIndex].items.forEach(
      (item, itemIndex) => {
        onActivityRowDurationChange(
          previousItems[itemIndex]?.duration || 0,
          activityIndex,
          itemIndex,
          index,
        );
      },
    );
  };

  useEffect(() => {
    /**
     * The function `colSum` calculates the total duration of a specific column in a table data array.
     * @param columnIndex - The `columnIndex` parameter in the `colSum` function represents the index of
     * the column for which you want to calculate the sum of durations in the `projects`.
     * @returns The function `colSum` returns the total duration of all rows in the `tableData` array at
     * the specified `columnIndex`.
     */
    const colSum = (columnIndex) => {
      let totalDuration = 0;

      projects.forEach((row) => {
        totalDuration += row?.totalRowHeaders[columnIndex]?.duration;
      });

      return totalDuration;
    };

    const updatedColumns = [...tableColumns];

    updatedColumns.forEach((column, index) => {
      column.total = colSum(index);
    });

    setTableColumns(updatedColumns);
  }, [projects]);

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      // style={{ width: '100vw' }}
    >
      <div>
        <GridTableHeader tableColumns={tableColumns} />
        <GridTableTotalHeader
          update={onTotalHeaderDurationChange}
          tableColumns={tableColumns}
          total={headerRowSum()}
          disabled={disabled}
          max={projects.length * 24}
        />
        <ScrollableContent
          minHeight={`calc(100vh - 370px${isSubmitted ? ' - 46px' : ' - 0px'})`}
          maxHeight={`calc(100vh - 370px${isSubmitted ? ' - 46px' : ' - 0px'})`}
        >
          {projects?.map((row, index) => (
            <Fragment key={`${row.projectName}-${row.projectId}`}>
              <GridTableBodyRow
                row={row}
                rowHeaders={row.totalRowHeaders}
                index={index}
                disabled={disabled}
                onAddNewRow={addActivityRow}
                onBodyRowTotalHeaderDurationChange={
                  onBodyRowTotalHeaderDurationChange
                }
                update={(event, rowIndex, colIndex) => {
                  onBodyRowDurationChange(
                    event.target.value,
                    rowIndex,
                    colIndex,
                  );
                }}
                toggleActivity={(row) => {
                  row.isExpanded = !row.isExpanded;
                  setProjects([...projects]);
                }}
              />
              {row?.activityRows?.map((activity, activityIndex) => (
                <GridTableBodyActivityRow
                  key={`${row.projectId}-row-${activityIndex}`}
                  row={row}
                  index={index}
                  max={120}
                  disabled={disabled}
                  onRemoveNewRow={removeActivityRow}
                  onActivityValueChange={onActivityValueChange}
                  onActivityRowTotalHeaderDurationChange={(parseValue, index) =>
                    onActivityRowTotalHeaderDurationChange(
                      parseValue,
                      index,
                      activityIndex,
                    )
                  }
                  update={(event, rowIndex, colIndex) => {
                    onActivityRowDurationChange(
                      Number(event.target.value),
                      rowIndex,
                      colIndex,
                      index,
                    );
                  }}
                  activity={activity}
                  activityIndex={activityIndex}
                />
              ))}
            </Fragment>
          ))}
        </ScrollableContent>
      </div>
    </Form>
  );
};
