// React imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import {
  Button,
  Card,
  InputGroup,
  Pagination,
  Stack,
  Table,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { getAssignedProjects } from 'src/redux/features/session/slice';

// App imports
import { TableLoader } from 'src/views/TimeSheet/components/TableLoader';

const ResourceList = () => {
  const columns = [
    'Project',
    'Start Date',
    'End Date',
    'Stage',
    'Team Member Count',
  ];

  const { data: projects, loading: projectsLoading } =
    useSelector(getAssignedProjects);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(projects.length);

  // Calculate the index of the first and last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items to display on the current page
  const currentItems = projects?.slice(indexOfFirstItem, indexOfLastItem);

  // Change the current page
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Change the number of items per page
  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when changing the number of items per page
  };

  useEffect(() => {
    setTotalItems(projects.length);
  }, [projects]);

  return (
    <Card bg="light" text="dark" className="h-100 bg-white border-0 shadow">
      <Card.Header className="bg-white my-1 border-0">
        <Stack direction="horizontal" className="justify-content-between">
          <span className="fs-4">Projects</span>
          <Link to="/manage-resource/add">
            <Button className="text-white">Add Resource</Button>
          </Link>
        </Stack>
      </Card.Header>
      <Card.Body className="h-full pt-0 overflow-hidden">
        <ScrollableContent minHeight="100%" maxHeight="100%">
          {projectsLoading ? <TableLoader /> : null}
          {!projectsLoading ? (
            <Table striped>
              <thead
                className="position-sticky top-0 bg-white"
                style={{ zIndex: 1000 }}
              >
                <tr className="border-1">
                  {columns.map((col, index) => (
                    <th
                      className="text-start fw-bolder p-3"
                      key={`${col}-${index}`}
                    >
                      {col}
                    </th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((row) => (
                  <tr key={row.id} className="text-start border-1">
                    <td className="fw-bold p-3">{row?.name}</td>
                    <td className="p-3">{row?.startDate || '-'}</td>
                    <td className="p-3">{row?.endDate || '-'}</td>
                    <td className="p-3">{row?.pmo_phase || '-'}</td>
                    <td className="p-3">{row?.resources?.length || 0}</td>
                    <td>
                      <Link to={`/manage-resource/edit/${row.id}`}>
                        <Button variant="link">
                          <i className="bi bi-pencil text-secondary fs-5"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
                {!currentItems?.length ? (
                  <tr className="p-5">
                    <td colSpan={6} className="p-5">
                      No Data available
                    </td>
                  </tr>
                ) : (
                  ''
                )}
              </tbody>
            </Table>
          ) : null}
        </ScrollableContent>
      </Card.Body>
      <Card.Footer className="border-0 d-flex align-items-center py-2">
        <span className="me-2">Move to page: </span>

        <Pagination className="prjects-pagination">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {/* Render pagination page numbers */}
          <Pagination.Item>
            {indexOfFirstItem + 1} to{' '}
            {indexOfLastItem < totalItems ? indexOfLastItem : totalItems} of{' '}
            {totalItems}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(projects?.length / itemsPerPage)
            }
          />
        </Pagination>
      </Card.Footer>
    </Card>
  );
};

export default ResourceList;
