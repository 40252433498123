// React imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import { Typeahead } from 'react-bootstrap-typeahead';

// App imports
import { IReportee } from 'src/models/Reportee';

export const SelectReportee = ({ data = [], onItemChange }) => {
  const [options, setOptions] = useState<IReportee[]>(data);
  const filterByFields = ['FirstName', 'LastName'];

  useEffect(() => {
    setOptions(data);
  }, [data]);

  return (
    <Typeahead
      className="w-25"
      filterBy={filterByFields}
      labelKey="UserPrincipalName"
      id="UserPrincipalName"
      options={options}
      placeholder="Select Reportee"
      onChange={onItemChange}
      allowNew={false}
      clearButton
      renderMenuItemChildren={(option: IReportee) => (
        <div key={option.UserPrincipalName}>
          {option?.FirstName} {option?.LastName}
          <div>
            <small>{option?.UserPrincipalName}</small>
          </div>
        </div>
      )}
    />
  );
};
