// React imports
import React from 'react';

// Third party imports
import { useAuth } from 'react-oidc-context';
import { Navigate, useLocation } from 'react-router-dom';

function RequireAuth({ children }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export default RequireAuth;
