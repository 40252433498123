import dayjs from 'dayjs';
import React, { forwardRef, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { generateWeekDates } from 'src/utils/helpers';

const CustomWeekInput = forwardRef(({ value, onClick }: any, ref: any) => {
  return (
    <Form.Control
      onClick={onClick}
      className="ps-0 rounded-0 rounded-end border-start-0"
      ref={ref}
      placeholder="Select Week"
      aria-label="Select Week"
      aria-describedby="Week picker for choosing the desired week"
      value={value}
    />
  );
});

CustomWeekInput.displayName = 'ExampleCustomInput';

export const WeekPicker = ({ startDate, onWeekChange, weekType }) => {
  const [date, setDate] = useState(startDate);
  const ref = useRef(null);

  return (
    <div className="position-relative">
      <DatePicker
        ref={ref}
        selected={date}
        onChange={(selectedDate) => {
          setDate(selectedDate);
          const weekId = ref.current.input.value.split('/')[0];

          onWeekChange(selectedDate, weekId);
        }}
        locale="en"
        placeholderText="Select Week"
        highlightDates={
          date
            ? generateWeekDates(
                dayjs(date).startOf('week'),
                dayjs(date).endOf('week'),
                weekType === 'work_week' ? true : false,
              )
            : []
        }
        customInput={<CustomWeekInput />}
      />

      <div className="end-0 position-absolute top-0 py-1">
        <Button
          variant="link"
          className="text-dark d-inline align-items-center justify-content-between text-decoration-none py-0"
          data-bs-toggle="collapse"
          onClick={() => {
            const newDate = dayjs(date).add(-1, 'week').format('YYYY-MM-DD');

            setDate(newDate);
            onWeekChange(newDate, 0);
          }}
        >
          <i title="Previous Week" className="bi bi-chevron-left"></i>
        </Button>

        <Button
          variant="link"
          className="text-dark d-inline align-items-center justify-content-between text-decoration-none py-0"
          data-bs-toggle="collapse"
          onClick={() => {
            const newDate = dayjs(date).add(1, 'week').format('YYYY-MM-DD');

            setDate(newDate);
            onWeekChange(newDate, 0);
          }}
        >
          <i title="Next Week" className="bi bi-chevron-right"></i>
        </Button>
      </div>
    </div>
  );
};
