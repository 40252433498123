export const ScrollableContent = ({ minHeight, maxHeight, children }) => {
  return (
    <div
      className=""
      style={{
        overflowY: 'auto',
        overflowX: 'clip',
        minHeight,
        maxHeight,
      }}
      // minHeight: 'calc(100vh - 550px)',
      // maxHeight: 'calc(100vh - 550px)',
    >
      {children}
    </div>
  );
};
