import React from 'react';

export const NoData = ({ message = 'No Data Available.' }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: '50vh' }}
    >
      {message}
    </div>
  );
};
