// REact imports
import React, { useEffect, useState } from 'react';

// Third party imports
import { Stack, InputGroup, Form, Button } from 'react-bootstrap';

// App imports
import { WeekPicker } from '../WeekPicker';
import { useAuth } from 'react-oidc-context';
import { SelectReportee } from './SelectReportee';
import { IReportee } from 'src/models/Reportee';
import { useSelector } from 'react-redux';
import { getPreferences } from 'src/redux/features/session/slice';
import { Link } from 'react-router-dom';
import { CanView } from 'src/components/CanView';
import { ResourceAutoComplete } from 'src/components/ResourceAutoComplete';
import { ProjectsResourceAutoComplete } from 'src/components/ProjectsResourceAutoComplete';
import ResourceFilterHoc from './ResourceFilterHoc';

const TimesheetFilters = ({
  weekDate,
  onWeekChange,
  onWorkWeekChange,
  onReporteeChange,
  weekType,
  teamTime,
}) => {
  const auth = useAuth();

  const manager = auth?.user?.profile?.peoplemanager === 'True';

  const onUserChange = (userEmail) => {
    if (userEmail) {
      onReporteeChange(userEmail);
    }
  };

  return (
    <Stack
      direction="horizontal"
      className="justify-content-between p-3"
      gap={3}
    >
      <span className="fs-4 me-2">Timesheet</span>
      <InputGroup
        className="bg-white w-auto me-auto"
        style={{ minWidth: '30%' }}
      >
        <InputGroup.Text id="basic-addon1" className="bg-white border-end-0">
          <i className="bi bi-calendar-week"></i>
        </InputGroup.Text>
        <WeekPicker
          startDate={weekDate}
          onWeekChange={onWeekChange}
          weekType={weekType}
        />
      </InputGroup>

      <Link to="/manage-resource/add" className="me-2">
        <Button className="text-white">Add Project</Button>
      </Link>

      <Form.Select
        aria-label="Default select Week Type"
        className="w-auto"
        // disabled
        onChange={onWorkWeekChange}
      >
        <option disabled>Select Week Type</option>
        <option selected={weekType === 'week'} value="week">
          Week
        </option>
        <option selected={weekType === 'work_week'} value="work_week">
          Work Week
        </option>
      </Form.Select>

      <ResourceFilterHoc teamTime={teamTime} onUserChange={onUserChange} />
    </Stack>
  );
};

export { TimesheetFilters };
