// React imports
import React, { useState } from 'react';

// Third-party imports
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from 'react-redux';

// App imports
import { IProjectResponse } from 'src/models/Project';
import { searchProjects } from 'src/redux/features/projects/projectsSlice';

export const ProjectsAutoComplete = ({ onSelectedProjectChange }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<IProjectResponse[]>([]);

  const handleSearch = async (query: string) => {
    setIsLoading(true);

    const response = await dispatch(searchProjects({ query }));

    setOptions(response.payload);
    setIsLoading(false);
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id="async-example"
      isLoading={isLoading}
      labelKey="name"
      minLength={3}
      onSearch={handleSearch}
      options={options}
      onChange={onSelectedProjectChange}
      placeholder="Select Project"
      renderMenuItemChildren={(option: IProjectResponse) => (
        <>
          <span>{option.name}</span>
        </>
      )}
      style={{ width: '400px' }}
    />
  );
};
