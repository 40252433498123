// React imports
import React from 'react';

// Third party imports
import { Spinner } from 'react-bootstrap';

export const FullScreenLoader = ({
  title,
  showLoader = true,
}: {
  title?: string;
  showLoader?: boolean;
}) => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: '100vh', width: '100vw' }}
    >
      {showLoader && (
        <Spinner
          animation="border"
          role="status"
          variant="primary"
          className="border-2"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {title || 'Loading...'}
    </div>
  );
};
