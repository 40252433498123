import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { CanView } from 'src/components/CanView';
import { ProjectsResourceAutoComplete } from 'src/components/ProjectsResourceAutoComplete';
import { ResourceAutoComplete } from 'src/components/ResourceAutoComplete';
import { SelectReportee } from './SelectReportee';
import { IReportee } from 'src/models/Reportee';

const ResourceFilterHoc = ({ teamTime, onUserChange }) => {
  const auth = useAuth();

  const [selectedReportee, setSelectedReportee] = useState<string>();
  const reportees = (auth?.user?.profile?.reportee as string) || '[]';

  const [parsedReportees, setParsedReportees] = useState();

  const onSelectedReporteeChange = (selected) => {
    if (selected && selected[0]) {
      if (selected[0].customOption) {
        onUserChange(selected[0].UserPrincipalName);
      } else {
        onUserChange(selected[0]);
      }

      setSelectedReportee(selected[0]);
    } else {
      setSelectedReportee(null);
      onUserChange(null);
    }
  };

  const onSelectedResourceChange = (selected) => {
    if (selected && selected[0]) {
      setSelectedReportee(selected[0].email);
      onUserChange(selected[0].email);
    }
    if (selected.length === 0) {
      setSelectedReportee('');
    }
  };

  useEffect(() => {
    setParsedReportees(JSON.parse(reportees));
  }, [reportees]);

  if (!teamTime) {
    return null;
  }

  return (
    <>
      <CanView userRole={auth.user.profile.role} allowedRoles={['CapexAdmin']}>
        <ResourceAutoComplete
          selectedItems={selectedReportee ? [selectedReportee] : []}
          onSelectedResourceChange={onSelectedResourceChange}
        />
      </CanView>
      <CanView
        userRole={auth.user.profile.role}
        allowedRoles={['CapexProjectLeader']}
      >
        <ProjectsResourceAutoComplete
          onSelectedProjectChange={onSelectedResourceChange}
        />
      </CanView>
      <CanView
        userRole={auth.user.profile.role}
        allowedRoles={['CapexAdManager']}
      >
        <SelectReportee
          data={parsedReportees}
          onItemChange={onSelectedReporteeChange}
        />
      </CanView>
    </>
  );
};

export default ResourceFilterHoc;
