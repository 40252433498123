// React imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from 'react-redux';

// App imports
import { IResource } from 'src/models/Resource';
import { fetchAssignedProjectsByResource } from 'src/redux/features/projects/projectsSlice';

export const ProjectsResourceAutoComplete = ({ onSelectedProjectChange }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<IResource[]>([]);

  const fetchData = async () => {
    setIsLoading(true);

    const response = await dispatch(
      fetchAssignedProjectsByResource({ resource: '' }),
    );

    const allResources = response.payload
      .map((project) => project.resources)
      .flat();
    const uniqueResources: any = [
      ...new Set(allResources.map((resource) => resource.resource)),
    ];

    setOptions(uniqueResources);
    console.log(uniqueResources);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Typeahead
      className="w-25"
      caseSensitive={true}
      id="filtering-example2"
      options={options}
      placeholder="Select User"
      onChange={onSelectedProjectChange}
    />
  );
};
