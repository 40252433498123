import React from 'react';
import { CanView } from '../CanView';
import { useAuth } from 'react-oidc-context';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import { Image } from 'react-bootstrap';

export const SideNavItems = () => {
  const auth = useAuth();

  return (
    <nav>
      <ul
        className="px-3"
        style={{
          listStyleType: 'none',
        }}
      >
        {/* <CanView
          userRole={auth.user.profile.role}
          allowedRoles={['CapexAdmin']}
        >
          <li className="d-flex align-items-center justify-content-start text-primary py-2">
            <i className="bi bi-card-text fs-5"></i>
            <NavLink to="/" className="text-decoration-none ms-2">
              Dashboard
            </NavLink>
          </li>
        </CanView> */}
        <li className="d-flex align-items-center justify-content-start text-primary py-2">
          <i className="bi bi-calendar2-minus fs-5"></i>
          <NavLink to="/timesheet" className="text-decoration-none ms-2">
            Time Sheet
          </NavLink>
        </li>

        <CanView
          userRole={auth.user.profile.role}
          allowedRoles={['CapexAdmin']}
        >
          <li className="d-flex align-items-center justify-content-start text-primary py-2">
            <i className="bi bi-calendar2-minus fs-5"></i>
            <NavLink to="/team-timesheet" className="text-decoration-none ms-2">
              Team Time Sheet
            </NavLink>
          </li>
        </CanView>

        <CanView
          userRole={auth.user.profile.role}
          allowedRoles={['CapexAdmin']}
        >
          <li className="d-flex align-items-center justify-content-start text-primary py-2">
            <i className="bi bi-calendar2-minus fs-5"></i>
            <NavLink to="/monthlysheet" className="text-decoration-none ms-2">
              Monthly Sheet
            </NavLink>
          </li>
        </CanView>

        <CanView
          userRole={auth.user.profile.role}
          allowedRoles={['CapexAdmin']}
        >
          <li className="d-flex align-items-center justify-content-start text-primary py-2">
            <i className="bi bi-border-style fs-5"></i>
            <NavLink
              to="/manage-resource"
              className="text-decoration-none ms-2"
            >
              Manage Resources
            </NavLink>
          </li>
        </CanView>
        {/* Add more menu items here */}
      </ul>
    </nav>
  );
};
