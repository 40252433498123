// App imports
import { useEffect, useState } from 'react';
import EditInput from '../../../../components/Input';

export const GridTableTotalHeader = ({
  tableColumns,
  total,
  update,
  disabled,
  max,
}) => {
  const [totalDuration, setTotalDuration] = useState(total);

  useEffect(() => {
    setTotalDuration(total);
  }, [total]);

  return (
    <div className="row border-bottom m-0">
      <div className="col-4 d-flex align-items-center justify-content-between">
        <span className="px-3">Total</span>
        <EditInput
          rowIndex={-1}
          colIndex={-1}
          update={update}
          value={totalDuration}
          className="w-25"
          isTotalInput
          disabled
        />
      </div>
      {tableColumns.map((column, columnIndex) => (
        <div
          className="col d-flex justify-content-center px-2 py-3"
          key={`header-${columnIndex}`}
        >
          <EditInput
            rowIndex={-1}
            colIndex={columnIndex}
            update={update}
            value={column.total}
            isTotalInput
            disabled={disabled}
            max={max}
            // style={{
            //   backgroundColor:
            //     columnIndex === 0 || columnIndex === 6 ? '#F6F189' : '',
            // }}
          />
        </div>
      ))}
    </div>
  );
};
