// Third party imports
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const AccessDeniedModal = ({ show, onHide }) => {
  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="bg-secondary bg-light border-0">
        <Modal.Title className="">
          <i className="bi bi-exclamation-triangle-fill me-2"></i>
          Access Denied
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mt-2 fs-6">
          You are not authorised to perform this action
        </p>
      </Modal.Body>
      <Modal.Footer className="p-1 border-0">
        <Button variant="primary text-light" onClick={onHide}>
          Home
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
