// React imports
import React from 'react';
import { Card } from 'react-bootstrap';
import DashboardTable from './components/DashboardTable';
import { ReportCards } from './components/ReprtCards';

const Dashboard = () => {
  const data = {
    id: 3,
    resource: 'deviprasad.das@daikinapplied.com',
    weekId: 1,
    startDate: '2024-02-28T20:47:46.334',
    endDate: '2024-02-28T20:47:46.334',
    items: [
      // Should return all 7 days with values 0 as default
      // Should be in an order of Mon to Sun
      {
        reportId: 1,
        reportName: 'Martinka Robert C',
        items: [
          {
            total: 31,
            submitted: 21,
          },
          {
            total: 31,
            submitted: 24,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 31,
          },
        ],
      },

      {
        reportId: 2,
        reportName: 'Baiotto, Robert M ',
        total: 31,
        submitted: 21,
        items: [
          {
            total: 31,
            submitted: 21,
          },
          {
            total: 31,
            submitted: 24,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 31,
          },
        ],
      },

      {
        reportId: 3,
        reportName: 'Church, Heather L',
        total: 31,
        submitted: 31,
        items: [
          {
            total: 31,
            submitted: 24,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 31,
          },
          {
            total: 31,
            submitted: 31,
          },
        ],
      },

      {
        reportId: 4,
        reportName: 'Lee, Judith A',
        total: 31,
        submitted: 31,
        items: [
          {
            total: 31,
            submitted: 21,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 31,
          },
          {
            total: 31,
            submitted: 31,
          },
        ],
      },

      {
        reportId: 5,
        reportName: 'Panda, Debendra K',
        total: 31,
        submitted: 31,
        items: [
          {
            total: 31,
            submitted: 21,
          },
          {
            total: 31,
            submitted: 24,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 31,
          },
          {
            total: 31,
            submitted: 31,
          },
        ],
      },

      {
        reportId: 6,
        reportName: 'Roy, Kathy',
        total: 31,
        submitted: 21,
        items: [
          {
            total: 31,
            submitted: 21,
          },
          {
            total: 31,
            submitted: 24,
          },
          {
            total: 31,
            submitted: 29,
          },
          {
            total: 31,
            submitted: 31,
          },
          {
            total: 31,
            submitted: 31,
          },
        ],
      },
    ],
  };

  const columns = [
    'Report Name',
    'December',
    'November',
    'October',
    'September',
    'August',
  ];

  return (
    <div className="p-3">
      <h2 className="text-start mb-4 mt-2">Dashboard</h2>
      <ReportCards />
      <Card bg="light" text="dark" className="my-2 bg-white border-0 shadow">
        <Card.Body className="h-full pt-0">
          <DashboardTable data={data} columns={columns} />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Dashboard;
