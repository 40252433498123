// React imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import {
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
  Stack,
  Table,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// App imports
import { ScrollableContent } from 'src/components/ScrollableContent';
import { IProjectDetails } from 'src/models/Project';
import {
  actions,
  assignProject,
  fetchProjectsById,
  getProjectById,
} from 'src/redux/features/projects/projectsSlice';
import { ResourceAutoComplete } from 'src/components/ResourceAutoComplete';
import { IResource } from 'src/models/Resource';
import { toast } from 'react-toastify';
import { fetchAssignedProjects } from 'src/redux/features/session/slice';
import { TableLoader } from 'src/views/TimeSheet/components/TableLoader';
import { formatDate } from 'src/utils/helpers';
import EndDateChangeModal from './EndDateChangeModal';

const EditProject = () => {
  const dispatch = useDispatch();
  const { id: projectId } = useParams();

  const columns = ['Resource', 'Start Date', 'End Date', 'Status'];

  const {
    data: projectDetails,
    loading: projectLoading,
  }: {
    data: IProjectDetails;
    loading: boolean;
  } = useSelector(getProjectById);

  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState<IResource[]>([]);
  const [deletedResources, setDeletedResources] = useState<string[]>([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addRow = () => {
    const newRow: IResource = {
      id: data.length + 1,
      resource: '',
      startDate: formatDate(new Date('2024-03-31')),
      endDate: formatDate(new Date('2025-04-01')),
      status: false,
      isActive: true,
      isNewResource: true,
    };

    const updatedDate = [...data];

    updatedDate.unshift(newRow);
    setData(updatedDate);
  };

  const removeRow = (index) => {
    const newData = [...data];

    newData.splice(index, 1);
    setDeletedResources([...deletedResources, data[index].resource]);
    setData(newData);
  };

  const onSelectedResourceChange = (selected, resource, index) => {
    if (selected && selected[0]) {
      if (selected[0].customOption) {
        data[index].resource = selected[0].email;
      } else {
        data[index].resource = selected[0].email;
      }
      setData([...data]);
    }
    if (selected.length === 0) {
      data[index].resource = '';

      setData([...data]);
    }
  };

  const getResourcesByProject = async (id: number) => {
    await dispatch(fetchProjectsById({ id }));
  };

  const onSave = async () => {
    const newResources = data.filter((item) => item.isNewResource);
    const existingResources = data.filter((item) => !item.isNewResource);

    const removedResources = projectDetails.resources
      .filter((item) => deletedResources.includes(item.resource))
      .map((item) => {
        item.isActive = false;

        return item;
      });

    const isResourcesValid = newResources.every((item) => item.resource !== '');
    const isEndDateEntered = newResources.every((item) => item.endDate !== '');

    if (!isResourcesValid) {
      toast.error('Please select resource name.');

      return;
    }

    if (!isEndDateEntered) {
      toast.error('Please select End Date for the Resource');

      return;
    }

    setIsSaving(true);

    await dispatch(
      assignProject({
        id: projectDetails.id,
        payload: [...existingResources, ...newResources, ...removedResources],
      }),
    );

    setIsSaving(false);
    toast.success('Resource updated successfully.');
    await dispatch(fetchAssignedProjects());
    await dispatch(actions.resetProjects());
    await getResourcesByProject(Number(projectId));

    const updatedDetails = [...data].map((item) => {
      return { ...item, isNewResource: false };
    });

    setData([...updatedDetails]);
  };

  const onEndDateChangeForAll = (endDateForAll) => {
    const nextData = [...data];

    nextData.forEach((item) => {
      item.endDate = formatDate(endDateForAll);
    });

    setData([...nextData]);
  };

  const onStartDateChange = (selectedDate, index) => {
    data[index].startDate = formatDate(selectedDate);

    setData([...data]);
  };

  const onEndDateChange = (date, index) => {
    data[index].endDate = formatDate(date);
    setData([...data]);
  };

  const onStatusChange = (index) => {
    data[index].status = !data[index].status;
    setData([...data]);
  };

  useEffect(() => {
    if (!projectLoading && projectDetails?.resources?.length) {
      setData(projectDetails.resources);
    } else {
      setData([]);
    }
  }, [projectDetails]);

  useEffect(() => {
    if (projectId) {
      getResourcesByProject(Number(projectId));
    }
  }, [projectId]);

  return (
    <Card bg="light" text="dark" className="h-100 bg-white border-0 shadow">
      <Card.Header className="bg-white border-0">
        <Stack
          direction="horizontal"
          className="align-items-center justify-content-between"
        >
          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between align-items-center"
          >
            <Link to="/manage-resource/list">
              <i className="bi bi-arrow-left-short fs-2"></i>
            </Link>
            <span className="fs-4">{projectDetails.name}</span>
          </Stack>
          {!projectLoading && projectId ? (
            <Stack
              direction="horizontal"
              gap={3}
              className="align-items-center justify-content-end"
            >
              <Button
                variant="outline-primary"
                onClick={handleShow}
                className="hover:text-white"
              >
                Update End Dates
              </Button>
              <Button className="text-white" onClick={addRow}>
                Add Resource
              </Button>
            </Stack>
          ) : (
            ''
          )}
        </Stack>
      </Card.Header>
      <Card.Body className="h-full pt-0 overflow-hidden">
        <ScrollableContent minHeight="100%" maxHeight="100%">
          {!projectLoading ? (
            <Table>
              <thead
                className="position-sticky top-0 bg-white"
                style={{ zIndex: 1000 }}
              >
                <tr className="border-1">
                  {columns.map((col, index) => (
                    <th
                      className="text-start fw-bolder p-3"
                      key={`${col}-${index}`}
                      style={{ width: index === 0 ? '40%' : 'auto' }}
                    >
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.length
                  ? data?.map((resource, resourceIndex) => (
                      <tr
                        key={`${resource.resource}-${resourceIndex}`}
                        className="text-start border-1"
                        style={{ width: resourceIndex === 0 ? '40%' : 'auto' }}
                      >
                        <td className="p-3">
                          <ResourceAutoComplete
                            selectedItems={
                              resource?.resource ? [resource?.resource] : []
                            }
                            disabled={!resource.isNewResource}
                            onSelectedResourceChange={(selected) => {
                              onSelectedResourceChange(
                                selected,
                                resource,
                                resourceIndex,
                              );
                            }}
                          />
                        </td>
                        <td className="p-3">
                          <InputGroup>
                            <InputGroup.Text id="basic-addon1">
                              <i className="bi bi-calendar-date"></i>
                            </InputGroup.Text>
                            <Form.Control
                              type="date"
                              value={resource?.startDate}
                              onChange={(e) =>
                                onStartDateChange(e.target.value, resourceIndex)
                              }
                              placeholder="Select Start Date"
                              aria-label="Select Start Date"
                              aria-describedby="Start Date"
                            />
                          </InputGroup>
                        </td>
                        <td className="p-3">
                          <InputGroup>
                            <InputGroup.Text id="basic-addon1">
                              <i className="bi bi-calendar-date"></i>
                            </InputGroup.Text>
                            <Form.Control
                              type="date"
                              value={resource?.endDate}
                              onChange={(e) =>
                                onEndDateChange(e.target.value, resourceIndex)
                              }
                              placeholder="Select End Date"
                              aria-label="Select End Date"
                              aria-describedby="End Date"
                            />
                          </InputGroup>
                        </td>
                        <td className="p-3">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label=""
                            className="mt-2"
                            defaultChecked={resource?.status}
                            onChange={() => onStatusChange(resourceIndex)}
                          />
                        </td>
                        {/* <td>
                          <Button
                            variant="link"
                            onClick={() => removeRow(resourceIndex)}
                          >
                            <i className="bi bi-trash3 fs-5"></i>
                          </Button>
                        </td> */}
                      </tr>
                    ))
                  : ''}
                {!data?.length ? (
                  <tr className="p-5 border-1">
                    <td colSpan={4} className="p-5">
                      No Data available
                    </td>
                  </tr>
                ) : (
                  ''
                )}
              </tbody>
            </Table>
          ) : (
            <TableLoader />
          )}

          <EndDateChangeModal
            show={show}
            onHide={handleClose}
            onEndDateChangeForAll={onEndDateChangeForAll}
          />
        </ScrollableContent>
      </Card.Body>
      {data?.length ? (
        <Card.Footer className="d-flex bg-white">
          <Button
            className="text-white ms-auto"
            disabled={isSaving}
            onClick={() => onSave()}
          >
            {isSaving ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2 border-1 text-dark"
                />
                Saving...
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Card.Footer>
      ) : (
        ''
      )}
    </Card>
  );
};

export default EditProject;
