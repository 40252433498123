export const GridTableHeader = ({ tableColumns }) => {
  return (
    <div className="row border-bottom border-dark m-0">
      <div className="p-3 col-4"></div>
      {tableColumns.map((column, columnIndex) => (
        <div
          className="p-2 fw-bold col mb-1"
          key={`${column.name}-${columnIndex}`}
          style={{
            fontSize: '.75rem',
            // backgroundColor:
            //   columnIndex === 0 || columnIndex === 6 ? '#F6F189' : '',
          }}
        >
          {column.name}
        </div>
      ))}
    </div>
  );
};
