import projectsReducer from './features/projects/projectsSlice';
import yearlyTimesheetReducer from './features/monthlysheet/monthlysheetSlice';
import sessionReducer from './features/session/slice';
import timesheetReducer from './features/timesheet/slice';

const rootReducer = {
  timesheet: timesheetReducer,
  yearlyTimesheet: yearlyTimesheetReducer,
  projects: projectsReducer,
  session: sessionReducer,
};

export default rootReducer;
