// React imports
import React from 'react';

// Third party imports
import { Stack } from 'react-bootstrap';

export const Legends = () => {
  return (
    <Stack direction="horizontal" gap={3} className="align-items-center">
      <div className="d-flex align-items-center ">
        <div
          className="me-2"
          style={{
            backgroundColor: '#E09101',
            width: '14px',
            height: '14px',
          }}
        ></div>
        <span>Overtime</span>
      </div>

      {/* <div className="d-flex align-items-center">
        <div
          className="me-2"
          style={{
            backgroundColor: '#DF1BFF',
            width: '14px',
            height: '14px',
          }}
        ></div>
        <span>Out of Office</span>
      </div> */}

      {/* <div className="d-flex align-items-center ">
        <div
          className="me-2"
          style={{
            backgroundColor: '#F6F189',
            width: '14px',
            height: '14px',
          }}
        ></div>
        <span>Holiday</span>
      </div> */}
    </Stack>
  );
};
