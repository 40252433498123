// React imports
import React, { useEffect, useState } from 'react';

// Third party imports
import {
  Alert,
  Button,
  Card,
  Spinner,
  Stack,
  Modal,
  Table,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

// App imports
import {
  ITimesheet,
  ITimesheetHistory,
  TimesheetModel,
  TimesheetStatusMap,
} from 'src/models/Timesheet';
import { GridTable } from './components/GridTable';
import { TableLoader } from './components/TableLoader';
import { NoData } from './components/NoData';
import { Legends } from './components/Legends';
import { ActivitySwitchFilter } from './components/Filters/ActivitySwitchFilter';
import { TimesheetFilters } from './components/Filters/TimesheetFilters';
import {
  getActivities,
  getAssignedProjects,
  getPreferences,
} from 'src/redux/features/session/slice';
import {
  actions,
  getTimesheet,
  getTimesheetByUser,
  getTimesheetHistory,
  getTimesheetHistoryData,
  saveTimesheet,
  submitTimesheet,
} from 'src/redux/features/timesheet/slice';
import { IReportee } from 'src/models/Reportee';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { getDateandTime } from 'src/utils/helpers';

const TimeSheet = ({ teamTime }: { teamTime?: boolean }) => {
  const dispatch = useDispatch();

  const timesheetModelObj = new TimesheetModel();
  const auth = useAuth();
  const manager = auth?.user?.profile?.peoplemanager === 'True';
  const admin = auth?.user?.profile?.role === 'CapexAdmin';

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [mode, setMode] = useState<'week' | 'activity'>('week');
  const [weekType, setWeekType] = useState<'week' | 'work_week'>('work_week');
  const [weekDate, setWeekDate] = useState(new Date());
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNodata, setShowNodata] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedReportee, setSelectedReportee] = useState('');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [timesheet, setTimesheet] = useState<ITimesheet>();
  const [history, setHistory] = useState<ITimesheetHistory[]>();

  const { data: response, loading: timeSheetLoading } =
    useSelector(getTimesheet);
  const {
    data: projects,
    loading: projectsLoading,
    status: projectsStatus,
  } = useSelector(getAssignedProjects);
  const { data: activities, loading: activitiesLoading } =
    useSelector(getActivities);
  const { history: historyData, historyLoading: timeSheetHistoryLoading } =
    useSelector(getTimesheetHistoryData);

  const onWorkWeekChange = (e) => {
    setWeekType(e.target.value);
    const workWeekTimesheet = timesheetModelObj.fromJson(
      { ...response },
      e.target.value === 'work_week',
      manager,
    );

    const newData = Object.assign({}, workWeekTimesheet);

    setTimesheet({ ...newData });
  };

  const onWeekChange = (date) => {
    setShowNodata(false);
    setWeekDate(date);
    setTimesheet(null);
    dispatch(
      getTimesheetByUser({
        resource: selectedReportee || auth?.user?.profile?.email,
        date: dayjs(date || new Date()).format('YYYY-MM-DD'),
      }),
    );
  };

  const onReporteeChange = (userEmail: string) => {
    setSelectedReportee(userEmail);
    setShowNodata(false);
    setTimesheet(null);
    dispatch(
      getTimesheetByUser({
        resource: userEmail || auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );
  };

  const onSave = async () => {
    setIsSaving(true);
    const serialisedTimesheet = TimesheetModel.toJson(timesheet);

    await dispatch(
      saveTimesheet({
        resource: selectedReportee || auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
        payload: serialisedTimesheet.items,
      }),
    );

    await dispatch(
      getTimesheetByUser({
        resource: auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );

    setIsSaving(false);
  };

  // Function to open the history modal
  const GetHistory = async () => {
    await dispatch(
      getTimesheetHistory({
        resource: selectedReportee || auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );
    setShowHistoryModal(true);
  };
  // Function to close the history modal
  const closeHistoryModal = () => {
    setHistory([]);
    setShowHistoryModal(false);
  };
  const confirmSubmit = () => {
    setShowConfirmModal(true);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const serialisedTimesheet = TimesheetModel.toJson(timesheet);

    await dispatch(
      submitTimesheet({
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
        resource: selectedReportee || auth?.user?.profile?.email,
        payload: {
          ...serialisedTimesheet,
          status: 1,
        },
      }),
    );

    await dispatch(
      getTimesheetByUser({
        resource: auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (response && response.id != null && !timeSheetLoading) {
      const formattedTimesheet = timesheetModelObj.fromJson(
        response,
        weekType === 'work_week',
        manager,
      );

      setTimesheet(formattedTimesheet);

      setShowNodata(!response.projects.length);
    } else {
      setTimesheet(null);
      if (!isFirstRender) {
        setShowNodata(true);
      }
    }
  }, [response]);

  useEffect(() => {
    if (
      auth?.user?.profile?.email &&
      projects?.length &&
      activities &&
      !timeSheetLoading &&
      !projectsLoading &&
      !activitiesLoading
    ) {
      dispatch(
        getTimesheetByUser({
          resource: auth?.user?.profile?.email,
          date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
        }),
      );
    }
  }, [
    auth?.user?.profile?.email,
    projects,
    activities,
    projectsLoading,
    activitiesLoading,
  ]);

  useEffect(() => {
    setIsFirstRender(false);
  }, [isFirstRender]);

  useEffect(() => {
    if (
      projectsStatus !== 'idle' &&
      !projectsLoading &&
      projects.length === 0
    ) {
      toast.error('No projects found');
    }
  }, [projects]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetTimesheet());
    };
  }, []);

  useEffect(() => {
    if (historyData && historyData.length && !timeSheetHistoryLoading) {
      setHistory(historyData);
    }
  }, [historyData]);

  return (
    <Card bg="light" text="dark" className="h-100 bg-white border-0 shadow">
      <TimesheetFilters
        weekDate={weekDate}
        onWeekChange={onWeekChange}
        onWorkWeekChange={onWorkWeekChange}
        onReporteeChange={onReporteeChange}
        weekType={weekType}
        teamTime={teamTime}
      />
      {TimesheetStatusMap[timesheet?.status] === 'Submitted' ? (
        <Alert variant="warning" className="w-90 mx-3 py-2 my-2">
          Timesheet Submitted on &nbsp;
          {dayjs(timesheet?.submissionDate).format('DD/MM/YYYY hh:mm')}
        </Alert>
      ) : (
        ''
      )}
      <Card.Body className="h-100 p-0 m-0">
        {!isSubmitting &&
        !isSaving &&
        !showNodata &&
        (projectsLoading || timeSheetLoading) &&
        !timeSheetHistoryLoading ? (
          <TableLoader />
        ) : (
          ''
        )}

        {!showNodata && timesheet && timesheet.projects.length ? (
          <GridTable
            data={timesheet}
            columns={timesheet.columns}
            startDate={timesheet.startDate}
            endDate={timesheet.endDate}
            disabled={timesheet.disabled || teamTime}
            weekType={weekType}
            isSubmitted={TimesheetStatusMap[response.status] === 'Submitted'}
          />
        ) : (
          ''
        )}
        {showNodata ? <NoData /> : ''}
        {!projectsLoading && projects.length === 0 ? (
          <NoData message="No projects linked." />
        ) : (
          ''
        )}
        <ConfirmModal
          title="Are you sure you want to submit the Timesheet?"
          description={`Once Timesheet is submitted, it can't be edited.`}
          show={showConfirmModal}
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            onSubmit();
          }}
        />
      </Card.Body>
      <Card.Footer className="d-flex align-items-center justify-content-between px-3 py-2 bg-white">
        <Legends />
        <Button
          variant="primary"
          style={{ width: '200px' }}
          className="text-white"
          onClick={async () => {
            await GetHistory();
          }}
        >
          {'History'}
        </Button>
        <Modal
          centered
          show={showHistoryModal}
          onHide={closeHistoryModal}
          size="xl"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>History Logs</Modal.Title>
          </Modal.Header>
          <ScrollableContent minHeight="100%" maxHeight="100%">
            {history ? (
              <Table striped>
                <thead
                  className="position-sticky top-0 bg-white"
                  style={{ zIndex: 1000 }}
                >
                  <tr>
                    <th className="pl-20">Event</th>
                    <th className="pl-20">Date</th>
                    <th className="pl-20">Time</th>
                    <th className="pl-28">By</th>
                    <th className="pl-20">Rejection Reason (if applicable)</th>
                  </tr>

                  <tr className="border-1">
                    {history?.map((col, index) => (
                      <th
                        className="text-start fw-bolder p-3"
                        key={`${col}-${index}`}
                      ></th>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {timeSheetHistoryLoading ? <TableLoader /> : null}
                  {history &&
                    history.length > 0 &&
                    history?.map((row) => (
                      <tr
                        key={row?.timeSheetId}
                        className="text-start border-1"
                      >
                        <td className="fw-bold p-3">{row?.event}</td>
                        <td className="p-3">
                          {getDateandTime(row?.date).localDate || '-'}
                        </td>
                        <td className="p-3">
                          {getDateandTime(row?.date).localTime || '-'}
                        </td>
                        <td className="p-3">{row?.eventCreatedBy || '-'}</td>
                        <td className="p-3">{row?.reason}</td>
                        <td></td>
                      </tr>
                    ))}
                  {history?.length === 0 ? (
                    <tr className="p-5">
                      <td colSpan={6} className="p-5 text-center">
                        No Data available
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </tbody>
              </Table>
            ) : null}
          </ScrollableContent>
        </Modal>
        {!selectedReportee && timesheet && timesheet.projects.length ? (
          <Stack direction="horizontal" gap={3}>
            <Button
              className="d-flex align-items-center justify-content-center"
              variant="outline-primary"
              onClick={onSave}
              disabled={timesheet.disabled || isSaving}
            >
              {isSaving ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2 border-1 text-dark"
                  />
                  Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="primary"
              style={{ width: '200px' }}
              className="text-white"
              onClick={confirmSubmit}
              disabled={timesheet.disabled || isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2 border-1 text-dark"
                  />
                  Submitting...
                </>
              ) : (
                'Submit'
              )}
            </Button>
          </Stack>
        ) : (
          ''
        )}
      </Card.Footer>
    </Card>
  );
};

export default TimeSheet;
