import React, { useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { formatDate } from 'src/utils/helpers';

const EndDateChangeModal = ({ show, onHide, onEndDateChangeForAll }) => {
  const [endDateForAll, setEndDateForAll] = useState(formatDate(new Date()));

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update End Dates</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <InputGroup>
          <InputGroup.Text id="basic-addon1">
            <i className="bi bi-calendar-date"></i>
          </InputGroup.Text>
          <Form.Control
            type="date"
            value={endDateForAll}
            onChange={(e) => setEndDateForAll(e.target.value)}
            placeholder="Select Start Date"
            aria-label="Select Start Date"
            aria-describedby="Start Date"
          />
        </InputGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary-outline" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onHide();
            onEndDateChangeForAll(endDateForAll);
          }}
          className="text-white"
        >
          Update End Dates
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EndDateChangeModal;
