// React Imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import { Card, Spinner, Stack, Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// App imports
import EditInput from '../../components/Input';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { ResourceAutoComplete } from 'src/components/ResourceAutoComplete';
import {
  yearlyTimesheet,
  fetchYearlyTimesheet,
  saveYearlyTimesheet,
} from 'src/redux/features/monthlysheet/monthlysheetSlice';

const MonthlySheet = () => {
  const dispatch = useDispatch();
  const max = 160;

  const monthList = [
    { id: '04-01-2023', name: 'Apr 23' },
    { id: '05-01-2023', name: 'May 23' },
    { id: '06-01-2023', name: 'Jun 23' },
    { id: '07-01-2023', name: 'Jul 23' },
    { id: '08-01-2023', name: 'Aug 23' },
    { id: '09-01-2023', name: 'Sep 23' },
    { id: '10-01-2023', name: 'Oct 23' },
    { id: '11-01-2023', name: 'Nov 23' },
    { id: '12-01-2023', name: 'Dec 23' },
    { id: '01-01-2024', name: 'Jan 24' },
    { id: '02-01-2024', name: 'Feb 24' },
    { id: '03-01-2024', name: 'Mar 24' },
  ];

  const [selectedResource, setSelectedResource] = useState<string>();
  const [sheet, setSheet] = useState<any>();
  const [isSaving, setIsSaving] = useState(false);
  const { data: projects, loading: projectsLoading } =
    useSelector(yearlyTimesheet);

  useEffect(() => {
    if (projects && projects?.length > 0) {
      setSheet(projects);
      debugger;
    } else {
      setSheet([]);
    }
  }, [projects]);

  const onChange = (e, rowIndex, colIndex) => {
    const parseValue = Number(e.target.value);

    console.log(e, rowIndex, colIndex);

    if (sheet?.length && sheet?.length > rowIndex) {
      const clone = JSON.parse(JSON.stringify(sheet));
      const project = clone[rowIndex];

      if (Object.keys(project.monthly).length > colIndex) {
        const key = Object.keys(clone[rowIndex].monthly)[colIndex];

        project.monthly[key] = parseValue;
        setSheet(clone);
      }
    }
  };

  const monthTotal = (colIndex) => {
    if (sheet && sheet?.length > 0) {
      const durations = sheet.map((proj) =>
        proj.monthly && colIndex < Object.keys(proj.monthly).length
          ? proj.monthly[Object.keys(proj.monthly)[colIndex]]
          : 0,
      );

      return durations.reduce((a, b) => a + b, 0);
    }

    return 0;
  };

  const onResourceSelected = (selected) => {
    console.log(selected);
    if (selected && selected.length > 0) {
      setSelectedResource(selected[0].email);
      dispatch(fetchYearlyTimesheet({ resource: selected[0].email }));
    }
    if (selected.length === 0) {
      setSelectedResource(null);
    }
  };

  const onSave = async () => {
    setIsSaving(true);
    await dispatch(
      saveYearlyTimesheet({
        resource: selectedResource,
        timesheets: sheet,
      }),
    );

    setIsSaving(false);
  };

  useEffect(() => {
    setSelectedResource(null);
    setSheet(null);
    setIsSaving(false);
  }, []);

  return (
    <Card bg="light" text="dark" className="my-2 bg-white border-0 shadow">
      <Card.Header className="bg-white p-4 m-2 border-0">
        <Stack direction="horizontal" gap={3} className="justify-content-start">
          <ResourceAutoComplete
            selectedItems={selectedResource ? [selectedResource] : []}
            onSelectedResourceChange={(selected) => {
              onResourceSelected(selected);
            }}
          />
        </Stack>
      </Card.Header>
      <Card.Body className="h-full pt-0">
        <ScrollableContent minHeight="60vh" maxHeight="60vh">
          {projectsLoading && (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2 border-1 text-dark"
              />
              Loading...
            </>
          )}
          {sheet?.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Project</th>
                  {monthList.map((col, index) => (
                    <th
                      className="text-start fw-bolder p-2"
                      key={`${col.id}-${index}`}
                    >
                      {col.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sheet?.length > 0 &&
                  sheet?.map((project, rowIndex) => (
                    <tr key={`${project.projectId}`}>
                      <td className="text-primary">{project.projectName}</td>
                      {project?.monthly &&
                        Object.keys(project?.monthly) &&
                        Object.keys(project?.monthly).map((month, colIndex) => (
                          <td
                            key={`${project.id}-${month}-${colIndex}`}
                            className="p-3"
                          >
                            <EditInput
                              rowIndex={rowIndex}
                              colIndex={colIndex}
                              step={1}
                              update={onChange}
                              value={project.monthly[month]}
                              className="text-left width-fixed-75"
                              isTotalInput={false}
                              max={max}
                            />
                          </td>
                        ))}
                    </tr>
                  ))}
                <tr>
                  <td className="fw-bold">Total</td>
                  {monthList?.length > 0 &&
                    monthList?.map((month, index) => (
                      <td key={`${month.id}-${index}`} className="p-3">
                        <EditInput
                          rowIndex={-1}
                          colIndex={index}
                          update={onChange}
                          disabled={true}
                          value={monthTotal(index)}
                          className="text-left"
                          isTotalInput={true}
                          max={max}
                        />
                      </td>
                    ))}
                </tr>
              </tbody>
            </Table>
          ) : (
            'No data available'
          )}
        </ScrollableContent>
      </Card.Body>

      <Card.Footer className="d-flex align-items-center justify-content-between p-4 bg-white border-0">
        {sheet?.length > 0 && (
          <Stack direction="horizontal" gap={3}>
            <Button
              className="d-flex align-items-center justify-content-center"
              size="lg"
              variant="outline-primary"
              onClick={onSave}
              disabled={isSaving}
            >
              {isSaving ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2 border-1 text-dark"
                  />
                  Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Stack>
        )}
      </Card.Footer>
    </Card>
  );
};

export default MonthlySheet;
