// Third party imports
import { toast } from 'react-toastify';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// App imports
import { client } from 'src/utils/api';

export const fetchYearlyTimesheet: any = createAsyncThunk(
  'yearlyTimesheet',
  async ({ resource }: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`timesheet/yearly/${resource}`, {
        // body: { resource },
      });

      return response.data;
    } catch (err) {
      toast.error(`Error fetching assigned projects for ${resource}.`);

      return rejectWithValue(err);
    }
  },
);

export const saveYearlyTimesheet: any = createAsyncThunk(
  'yearlyTimesheet/save',
  async ({ resource, timesheets }: any, { rejectWithValue }) => {
    try {
      const response = await client.post(
        `Timesheet/yearly/${resource}`,
        timesheets,
      );

      toast.success('Timesheet saved Successfully');

      return response.data;
    } catch (err) {
      toast.error('Error saving timesheet.');

      return rejectWithValue(err);
    }
  },
);

enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

const monthlySheetSlice = createSlice({
  name: 'monthlytimesheet',
  initialState: {
    data: [],
    error: '',
    status: Status.idle,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchYearlyTimesheet.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [fetchYearlyTimesheet.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.status = Status.succeeded;
    },
    [fetchYearlyTimesheet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
    [saveYearlyTimesheet.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [saveYearlyTimesheet.fulfilled]: (state) => {
      state.loading = false;
      state.status = Status.succeeded;
    },
    [saveYearlyTimesheet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
  },
});

export const yearlyTimesheet = (state) => {
  return {
    loading: state.yearlyTimesheet.loading,
    data: state.yearlyTimesheet.data,
  };
};

export default monthlySheetSlice.reducer;
