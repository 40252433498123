import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const configureAppStore = (initialState = {}) => {
  const devMode = process.env.NODE_ENV === 'development';

  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const middleware = [sagaMiddleware];

  if (devMode) {
    // redux-logger options reference: https://www.npmjs.com/package/redux-logger#options
    const logOptions = {
      diff: true,
      duration: true,
      collapsed: (
        getState: () => void,
        action: Record<string, unknown>,
        logEntry: Record<string, unknown>,
      ) => !(logEntry.error as boolean),
    };

    middleware.push(createLogger(logOptions as any) as any);
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware: (gDM) => gDM().concat([...middleware]),
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = configureAppStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
