import dayjs from 'dayjs';
import {
  generateDates,
  isFutureDateAndNotInCurrentWeek,
  isSaturdayOrSunday,
} from 'src/utils/helpers';

export const TimesheetStatusMap = {
  0: 'Unknown',
  1: 'Submitted',
  2: 'Approved',
  3: 'Rejected',
  4: 'Resubmitted',
};

export interface IItem {
  date: string;
  duration: number;
  activityTypeId?: number;
  activityName?: string;
  projectId: number;
  disabled?: boolean;
}

export interface IProjectResponse {
  projectId: number;
  projectName: string;
  items: IItem[];
  lastWeekItems?: IItem[];
}

export interface ITimesheetRequestPayload {
  id?: number;
  startDate: string;
  endDate: string;
  items: {
    date: string;
    duration: number;
    activityTypeId: number;
    projectId: number;
  }[];
}

export interface ITimesheetResponse {
  id: number;
  resource: string;
  weekId: number;
  startDate: string;
  endDate: string;
  submissionDate?: string;
  projects: IProjectResponse[];
  status: number;
}

interface GroupedActivities {
  [key: string]: {
    items: IItem[];
  };
}
export interface ActivityItem {
  activityName: string;
  items: IItem[];
  isNew?: boolean;
  disabled?: boolean;
}

export interface IProject extends IProjectResponse {
  total: number;
  isExpanded: boolean;
  totalRowHeaders: IItem[];
  activityRows?: ActivityItem[];
  previousWeekActivityRows?: ActivityItem[];
  disabled?: boolean;
}

export interface TableColumnHeader {
  date: string;
  name: string;
  total: number;
}

export interface ITimesheet extends ITimesheetResponse {
  projects: IProject[];
  columns: TableColumnHeader[];
  futureWeek?: boolean;
  disabled?: boolean;
}

export interface ITimesheetHistory {
  timeSheetId: number;
  date: Date;
  event: string;
  eventCreatedBy: string;
  reason: string;
}

export class TimesheetModel {
  private groupItems = (
    items: IItem[],
    projectId: number,
  ): GroupedActivities => {
    return items.reduce((acc: GroupedActivities, item: IItem) => {
      const { activityName, ...rest } = item;

      if (!acc[activityName]) {
        acc[activityName] = {
          items: [],
        };
      }

      acc[activityName].items.push({
        ...rest,
        date: dayjs(rest.date).format('YYYY-MM-DD'),
        activityName,
        projectId,
      });

      return acc;
    }, {});
  };

  private getRowHeaders = (groupedItems) => {
    const rowHeaders = [];

    Object.keys(groupedItems).forEach((key) => {
      groupedItems[key].items.forEach((item, index) => {
        if (!rowHeaders[index]) {
          rowHeaders[index] = { duration: 0 };
        }
        rowHeaders[index].duration += item?.duration;
      });
    });

    return rowHeaders;
  };

  private getTableColumns = (dates: string[]): TableColumnHeader[] => {
    return dates.map((date) => {
      return {
        date,
        name: dayjs(date).format('ddd, DD MMM YYYY'),
        total: 0,
      };
    });
  };

  static getProjectActivityMap = (
    projects: IProject[],
  ): { [key: string]: { [key: string]: IItem[] } } => {
    const projectActivityMap: { [key: string]: { [key: string]: IItem[] } } =
      {};

    projects?.forEach((project) => {
      if (!projectActivityMap[project.projectId]) {
        projectActivityMap[project.projectId] = {};
      }
      project?.items?.forEach((activity) => {
        if (!projectActivityMap[project.projectId][activity.activityName]) {
          projectActivityMap[project.projectId][activity.activityName] = [];
        }
        projectActivityMap[project.projectId][activity.activityName].push(
          activity,
        );
      });
    });

    return projectActivityMap;
  };

  fromJson(
    response: ITimesheetResponse,
    workWeek = true,
    mangerView = false,
  ): ITimesheet {
    response = JSON.parse(JSON.stringify(response));
    const disableTimesheet =
      TimesheetStatusMap[response.status] === 'Submitted';

    const projects: IProject[] = response.projects.map(
      (project: IProjectResponse) => {
        const serialisedProject: IProject = {
          ...project,
          total: 0,
          isExpanded: false,
          totalRowHeaders: [],
          activityRows: [],
        };

        if (!project.items) {
          project.items = [];
        }

        project.items.sort((a, b) => {
          const diff = dayjs(a.date).diff(dayjs(b.date));

          return diff;
        });

        const filteredProjects = project?.items?.filter((item) => {
          return isSaturdayOrSunday(item.date) === false;
        });

        const filteredPreviousWeekProjects = project.lastWeekItems.filter(
          (item) => {
            return isSaturdayOrSunday(item.date) === false;
          },
        );

        const groupedItems = this.groupItems(
          workWeek ? [...filteredProjects] : project.items,
          project.projectId,
        );

        const groupedPreviousWeekItems = this.groupItems(
          workWeek ? [...filteredPreviousWeekProjects] : project.lastWeekItems,
          project.projectId,
        );

        serialisedProject.total = project?.items?.reduce(
          (sum: number, item: IItem) => sum + item.duration,
          0,
        );
        serialisedProject.activityRows = Object.keys(groupedItems).map(
          (key) => {
            return {
              activityName: key,
              ...groupedItems[key],
              disabled:
                mangerView ||
                disableTimesheet ||
                isFutureDateAndNotInCurrentWeek(response.startDate),
            };
          },
        );

        serialisedProject.previousWeekActivityRows = Object.keys(
          groupedPreviousWeekItems,
        ).map((key) => {
          return {
            activityName: key,
            ...groupedPreviousWeekItems[key],
          };
        });

        serialisedProject.totalRowHeaders = this.getRowHeaders(groupedItems);

        serialisedProject.disabled =
          mangerView || isFutureDateAndNotInCurrentWeek(response.startDate);

        return serialisedProject;
      },
    );

    const dates = generateDates(response.startDate, response.endDate, workWeek);

    return {
      ...response,
      futureWeek: isFutureDateAndNotInCurrentWeek(response.startDate),
      projects,
      columns: this.getTableColumns(dates),
      disabled:
        disableTimesheet ||
        mangerView ||
        isFutureDateAndNotInCurrentWeek(response.startDate),
    };
  }

  static getWeekEndsForProject = (
    items: IItem[],
    activityRow: ActivityItem,
  ) => {
    const weekEndItems = items
      .filter(
        (item) => item.activityTypeId === activityRow?.items[0].activityTypeId,
      )
      .filter((item) => isSaturdayOrSunday(item.date))
      .map((item) => {
        return {
          ...item,
          activityName: activityRow?.activityName,
          activityTypeId: activityRow?.items[0]?.activityTypeId,
          projectId: activityRow?.items[0]?.projectId,
        };
      });

    return weekEndItems;
  };

  static toJson(timesheet: ITimesheet): ITimesheetRequestPayload {
    const { startDate, endDate, projects } = JSON.parse(
      JSON.stringify(timesheet),
    );

    projects.forEach((project) => {
      project.activityRows.forEach((activityRow) => {
        if (activityRow.items.length === 5) {
          const weekEnds = TimesheetModel.getWeekEndsForProject(
            project.items,
            activityRow,
          );

          if (weekEnds.length) {
            activityRow.items = activityRow.items.concat(weekEnds);
          }
        }
      });
    });

    const flattenedItems = projects?.map((project) => {
      const flattened = project.activityRows.reduce((acc, activity) => {
        return acc.concat(...activity.items);
      }, []);

      return flattened;
    });

    const requestPayload = {
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      items: flattenedItems.flat(),
    };

    return requestPayload;
  }
}
