// Third party imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// App imports
import { client } from 'src/utils/api';

export const fetchAllUsers: any = createAsyncThunk(
  'users/fetchAllUsers',
  async ({ query }: any, { rejectWithValue }) => {
    try {
      // const params = {
      //   search: query,
      // };

      // const queryParams = Object.keys(params)
      //   .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      //   .join('&');

      const response = await client.get(`User/search/${query}`, {});

      let transformedData = [];

      if (response && response.data && Array.isArray(response.data)) {
        transformedData = response.data.map((item: any, index) => {
          return {
            name: item.name,
            id: item.WorkerRef || index,
            email: item.email,
          };
        });
      }

      return transformedData || [];
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: {},
    error: '',
    status: Status.idle,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchAllUsers.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [fetchAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
      state.status = Status.succeeded;
    },
    [fetchAllUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
  },
});

export const getUsers = (state) => state.users;

export default usersSlice.reducer;
